import styled from 'styled-components';

export const StyledBody = styled.div`
  .header_container {
    background: ${({ theme }) => (theme === 'white' ? '#fff' : 'linear-gradient(45deg, #ff5216, #fe9100)')};
    height: 80px;
    box-shadow: 0px 5px 10px rgb(65, 55, 55);
    z-index: ${({ openBurguerTwo }) => (openBurguerTwo ? '7' : '0')} !important;
    transition: ${({ open }) => (open ? 'all 0.1s linear' : 'all 1s linear')};
    position: sticky;
  }

  .containerHome {
    margin-left: 4%;
    margin-right: 4%;
  }

  .header-links {
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
  }

  .header-logo {
    max-height: 80px !important;
    display: flex;
    align-items: center;
  }

  .header-logo img {
    width: 300px;
    height: 44px;
    filter: brightness(${({ theme }) => (theme === 'dark' ? '9.5' : '1')});
  }

  .header-links a {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
    text-decoration: none;
    font-size: ${({ theme }) => (theme === 'white' ? '1.1vw' : '18px')};
    margin: 20px 0.2% 10px 0.2%;
    font-weight: ${({ theme }) => (theme === 'white' ? 'normal' : 'bold')};
    white-space: nowrap;
  }

  .header-links a:hover:not(:last-child) {
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
    border-bottom: 2px solid ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
  }

  .header-links a:not(:last-child) {
    height: 40px;
  }

  .whats_header {
    background: ${({ theme }) => (theme === 'white' ? 'linear-gradient(45deg,#ff5216,#fe9100)' : '#fff')} !important;
    height: 90px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0px 0px 16px #3e3a3a;
    text-decoration: none;
    color: ${({ theme }) => (theme === 'white' ? '#fff' : '#fe5b13')} !important;
    font-size: 19px !important;
    font-weight: bold !important;
    margin: 0px !important;
    margin-left: 20px !important;
    padding: 10px 20px !important;
  }

  @media only screen and (max-width: 1515px) and (min-width: 1381px) {
    .containerHome {
      margin-left: 2%;
      margin-right: 2%;
    }

    .header-links a {
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
      text-decoration: none;
      font-size: 1vw !important;
      padding: 10px;
    }

    .header-links a:not(:last-child) {
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
      text-decoration: none;
      font-size: 1.7vw;
      margin: 20px 0.5vw 10px 0.5vw !important;
      font-weight: bold;
      white-space: nowrap;
    }

    .header-logo img {
      width: 250px;
      height: 35px;
      filter: brightness(${({ theme }) => (theme === 'dark' ? '9.5' : '1')});
    }

    .whats_header {
      width: 22vw;
      height: 90px;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 0px 15px 15px;
      box-shadow: 0px 0px 16px #3e3a3a;
      text-decoration: none;
      font-size: 1.5vw !important;
      font-weight: bold;
      margin: 0px !important;
    }
  }

  @media only screen and (max-width: 1380px) and (min-width: 801px) {
    .containerHome {
      margin-left: 2%;
      margin-right: 2%;
    }

    .header-links a {
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
      text-decoration: none;
      padding: 10px;
      font-weight: bold;
      white-space: nowrap;
    }

    .header-links a:not(:last-child) {
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
      text-decoration: none;
      font-size: 1vw !important;
      margin: 2vw 0.1vw 10px 0.1vw !important;
      font-weight: bold;
      white-space: nowrap;
    }

    .header-logo img {
      width: 250px;
      height: 35px;
      filter: brightness(${({ theme }) => (theme === 'dark' ? '9.5' : '1')});
    }

    .whats_header {
      width: 22vw;
      background: #fff;
      height: 90px;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 0px 15px 15px;
      box-shadow: 0px 0px 16px #3e3a3a;
      text-decoration: none;
      font-size: 1.2vw !important;
      font-weight: bold;
      margin: 0px !important;
    }
  }

  @media only screen and (max-width: 1080px) and (min-width: 801px) {
    .header-links a:not(:last-child) {
      margin: 30px 0.3vw 10px 0.3vw !important;
    }

    .header-logo img {
      width: 8vw;
      height: auto;
      filter: brightness(9.5);
    }
  }

  @media only screen and (max-width: 800px) and (min-width: 436px) {
    .containerHome {
      margin-left: 0%;
      margin-right: 0%;
      z-index: 1;
      position: sticky;
    }

    .header-logo {
      width: 50%;
      margin: auto;
    }

    .header-logo img {
      min-width: 300px  !important;
      height: 44px;
      margin: 20px 5% 10px auto;
      filter: brightness(${({ theme }) => (theme === 'dark' ? '9.5' : '1')});
    }

    .header-links a:not(:last-child) {
      transition: all 0.2s ease-in-out;
    }
  }

  @media only screen and (max-width: 435px) and (min-width: 385px) {
    .header-logo-row{
      display: block;
      width: 100%;
      margin: 0px;
      max-height: 100px;
    }
    
    .containerHome {
      margin-left: 0%;
      margin-right: 0%;
      z-index: 1;
      position: sticky;
      width: 100%;
    }

    .header-logo {
      width: 50% !important;
      margin-left: 23%;
    }

    .header-logo img {
      min-width: 230px !important;
      height: 35px;
      margin: 24px 5% 10px auto;
    }

    .header-links a:not(:last-child) {
      transition: all 0.2s ease-in-out;
    }
  }

  @media only screen and (max-width: 569px) {
    .header-links {
      background: ${({ theme }) => (theme === 'white' ? '#fff' : 'linear-gradient(80deg,#ff5216,#fe9100)')} !important;
      padding: 10px;
    }

    .header-links a:not(:last-child) {
      border-bottom: 1px solid #ff5216 !important;
      font-size: 6vw;
      transition: all 0.2s ease-in-out;
    }

    .whats_header {
      margin: 0px auto !important;
      width: 90%;
    }
  }

  @media only screen and (max-width: 384px) {
    .containerHome {
      margin-left: 0%;
      margin-right: 0%;
      z-index: 1;
      position: sticky;
    }

    .header-logo {
      min-height: 60px;
      width: 50% !important;
      margin-left: 18%;
    }

    .header-logo img {
      min-width: 260px !important;
      height: 40px;
      margin: 20px 5% 10px auto;
    }

    .header-links a:not(:last-child) {
      transition: all 0.2s ease-in-out;
    }
  }
`;

StyledBody.defaultProps = {
  theme: 'dark',
};

export const StyledBurguer = styled.div`
  @media only screen and (max-width: 800px) {
    width: 2rem;
    height: 1.50rem;
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    cursor: pointer;

    div {
      width: 2.60rem;
      height: 0.25rem;
      background-color: ${({ openBurguer, theme }) => (openBurguer ? '#d4d4d4' : (theme === 'white' ? '#404041' : '#fff'))};
      border-radius: 10px;
      transform-origin: 6.5px;
      transition: all 0.5s linear;

      &:nth-child(1) {
        transform: ${({ openBurguer }) => (openBurguer ? 'rotate(45deg)' : 'rotate(0)')};
      }

      &:nth-child(2) {
        opacity: ${({ openBurguer }) => (openBurguer ? 0 : 1)};
      }

      &:nth-child(3) {
        transform: ${({ openBurguer }) => (openBurguer ? 'rotate(-45deg)' : 'rotate(0)')};
      }
    }
  }
`;

export const StyledMenu = styled.div`
  .buttonSegmentos {
    display: none;
  }

  .only_desktop {
    display: inline;
  }

  @media only screen and (max-width: 1000px){
    .logoHeader, .header-logo img {
      width: 6vw !important;
    }
  }

  @media only screen and (max-width: 1400px){
    .logoHeader {
      width: 12vw !important;
    }
  }

  @media only screen and (max-width: 1700px){
    .logoHeader {
      width: 8vw !important;
      height: auto !important;
    }
  }

  @media only screen and (max-width: 800px) and (min-width: 570px) {
    .logoHeader {
      margin-left: auto;
    }

    .hrefSegmentos {
      display: none;
    }

    .buttonSegmentos {
      display: block;
    }

    .only_desktop {
      display: none;
    }

    .header-links {
      background: linear-gradient(80deg, #ff5216, #fe9100);
      border: none;
      height: auto;
      width: 100%;
      flex-flow: column nowrap;
      padding: 10px;
      transition: all 0.5s linear;
      transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-200%)')};
      opacity: ${({ open }) => (open ? 1 : 0.5)};
    }

    .whats_header {
      background: #fff;
      height: 91px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      text-decoration: none;
      font-size: 22px !important;
      font-weight: bold;
      margin: 22px !important;
      margin-left: 20px !important;
    }
  }

  @media only screen and (max-width: 569px) {
    .containerHome .header-links {
      margin: 0px !important;
    }

    .only_desktop {
      display: none;
    }

    .hrefSegmentos {
      display: none;
    }

    .buttonSegmentos {
      display: block;
    }

    .header-links {
      background: linear-gradient(80deg, #ff5216, #fe9100);
      border: none;
      height: auto;
      width: 100% !important;
      flex-flow: column nowrap;
      padding: 10px;
      transition: all 0.5s linear;
      transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-200%)')};
      opacity: ${({ open }) => (open ? 1 : 0.5)};
      box-shadow: 3px 18px 16px -2px #ccc;
    }

    .whats_header {
      background: #fff;
      height: 91px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      text-decoration: none;
      font-size: 22px !important;
      font-weight: bold;
      margin: 22px !important;
      margin-left: 20px !important;
    }
  }
`;

export const StyledMenuSegmentos = styled.div`
  .header-segmentos {
    display: none;
  }

  @media only screen and (max-width: 800px) and (min-width: 570px) {
    .header-segmentos {
      display: inline;
      position: absolute;
      top: 75px;
      background: linear-gradient(80deg, #ff5216, #fe9100);
      border: none;
      z-index: ${({ openSegmentos }) => (openSegmentos ? '7' : '1')} !important;
      height: auto;
      width: 100% !important;
      flex-flow: column nowrap;
      transition: all 0.5s linear;
      transform: ${({ openSegmentos }) => (openSegmentos ? 'translateY(0)' : 'translateY(-150%)')};
      opacity: ${({ openSegmentos }) => (openSegmentos ? 1 : 0.5)};
    }

    .header-segmentos a {
      transition: all 0.2s ease-in-out;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      margin: 8vw 20px 5px 20px;
      padding: 10px;
      font-weight: bold;
      white-space: nowrap;
    }

    .itens_menu {
      display: flex;
      align-items: center;
      height: auto;
      border-bottom: 1px solid #fff;
    }

    .iconMenu {
      margin-top: 15px;
      margin-left: 5%;
      width: 10%;
      height: 10%;
      filter: ${({ theme }) => (theme === 'white' ? 'brightness(1)' : 'brightness(100000)')};
    }
  }

  @media only screen and (max-width: 569px) {
    .header-segmentos {
      display: inline;
      position: absolute;
      top: 78px;
      background: ${({ theme }) => (theme === 'white' ? '#fff' : 'linear-gradient(80deg, #ff5216, #fe9100)')};
      border: none;
      z-index: ${({ openSegmentos }) => (openSegmentos ? '7' : '1')} !important;
      height: auto;
      width: 100% !important;
      flex-flow: column nowrap;
      transition: all 0.5s linear;
      transform: ${({ openSegmentos }) => (openSegmentos ? 'translateY(0)' : 'translateY(-150%)')};
      opacity: ${({ openSegmentos }) => (openSegmentos ? 1 : 0.5)};
    }

    .header-segmentos a {
      transition: all 0.2s ease-in-out;
      color: ${({ theme }) => (theme === 'white' ? '#000' : '#fff')};
      text-decoration: none;
      font-size: 18px;
      margin: 7vw 20px 5px 20px;
      padding: 10px;
      font-weight: bold;
      white-space: nowrap;
    }

    .itens_menu {
      display: flex;
      align-items: center;
      height: auto;
      border-bottom: 1px solid #fff;
    }

    .iconMenu {
      margin-top: 15px;
      margin-left: 5%;
      width: 10%;
      height: 10%;
      filter: ${({ theme }) => (theme === 'white' ? 'brightness(1)' : 'brightness(100000)')};
    }
  }
`;
