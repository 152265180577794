import React, {useEffect} from "react";
import Header from "../../components/Header";
import TutorialList from "../../components/Body/TutorialList";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

const Tutoriais = () => {

    return(
        <>
        <Header /> 
        <TutorialList/>
        <Footer />
        </>
    )
  }
export default Tutoriais;
