import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import bgFirst from '../../../images/lp_datamais/bg_jimi.png';
import bgFirstmobile from '../../../images/lp_datamais/bg_jimi.png';
import mockup from '../../../images/lp_datamais/mockup.jpg';
import wavebot from '../../../images/lp_datamais/wavebot.svg';
import wavebotMobile from '../../../images/lp_datamais/waveBotMobile.svg';
import wavetopMobile from '../../../images/lp_datamais/wavetopMobile.svg';
import './style.js';
import { initPixelLp } from '../../../services/facebookPixel';
import { logPixelPageView } from '../../../services/facebookPixel';
import { initPixelConvTrkr } from '../../../services/facebookPixel';
import { initGA } from '../../../services/googleAnalytics';
import { initGoogleAdwords } from '../../../services/googleAnalytics';
import ContactModal from '../../ContactModal';


import { StyledBodyOne } from './style';

import jimi01 from '../../../images/jimi/jimi01.png';
import jimi02 from '../../../images/jimi/jimi02.png';
import jimi03 from '../../../images/jimi/jimi03.png';
import jimi04 from '../../../images/jimi/jimi04.png';
import jimi05 from '../../../images/jimi/jimi05.png';
import jimi06 from '../../../images/jimi/jimi06.png';
import jimi07 from '../../../images/jimi/jimi07.png';
import jimi08 from '../../../images/jimi/jimi08.png';

const CarroselJimi = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={jimi01} alt="Image 1" />
      </div>
      <div>
        <img src={jimi02} alt="Image 2" />
      </div>
      <div>
        <img src={jimi03} alt="Image 3" />
      </div>
      <div>
        <img src={jimi04} alt="Image 4" />
      </div>
      <div>
        <img src={jimi05} alt="Image 5" />
      </div>
      <div>
        <img src={jimi06} alt="Image 6" />
      </div>
      <div>
        <img src={jimi07} alt="Image 7" />
      </div>
      <div>
        <img src={jimi08} alt="Image 8" />
      </div>
    </Slider>
  );
};


const BodyLPJimi = () => {
  useEffect(() => {
    initPixelConvTrkr();
    logPixelPageView();
    initPixelLp();
  }, [])

  useEffect(() => {
    initGA();

  }, [])

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e) => {
      setModalIsOpen(true);
      e.preventDefault();
  };
  return (
    <StyledBodyOne>
      <div className="col-sm-12 infos-jimi">
        <div className='info-first-img'>
          <p>Atenda mais e melhor com a <br />pesagem automatizada pelo <br />Jimi Robô.</p>
          <p>Com o Jimi Robô seu estabelecimento buffet vai para o próximo nível.</p>
        </div>
        <div className='video-first'>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/6Ydiq1wrdys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <img src={bgFirst}
          className="mainImgLP"
          alt="First img"
        />
      </div>
      <div className="col-sm-12-Mobile">
        <div className='info-first-img'>
          <p>Atenda mais e melhor</p>
          <p>com a pesagem <br/>automatizada pelo<br/>Jimi Robô</p>
          <br/>
          <br/>
          <p>Com o Jimi Robô seu estabelecimento buffet vai para o próximo nível.</p>
        </div>
        <div className='video-first'>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/6Ydiq1wrdys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <img src={bgFirstmobile}
          className="mainImgLP"
          alt="First img"
        />
      </div>
      <div className='div-btn-contact-bodyLP button-animation'>
        <div className="btn-contact-bodyLP" onClick={e=>openModal(e)}>
          <a href="https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0">Fale com nossa equipe!</a>
        </div>
      </div>
      <img src={wavebotMobile}
        className="wavebotMobile"
        alt="wave"
      />

      <div className="carroselJimi">
        <CarroselJimi />
      </div>
      <ContactModal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} isExitIntent={'E'}/>
    </StyledBodyOne>
  )
}

export default BodyLPJimi;