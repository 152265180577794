import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import apiFormClient from '../../../services/apiForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { initPixelTrabalheConosco } from '../../../services/facebookPixel';
import { logPixelPageView } from '../../../services/facebookPixel';
import { initPixelConvTrkr } from '../../../services/facebookPixel';
import { initGA } from '../../../services/googleAnalytics';

const StyledBodyOneTC = styled.div`

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
  [type="file"] + label {
      width:22%;
    background: #f15d22;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    padding: 2%;
    position: relative;
    transition: all 0.3s;
    
    &:hover {
      background-color:(#f15d22, 10%);
    }
    
    &.btn-1 {
      background-color: #ff6e0c;
      box-shadow: 0 6px #ff5415;

  
      &:hover {
        box-shadow: 0 4px darken(#f79159, 10%);
        top: 3px;
      }
    }
  }
.infoOne{
    padding-top:1%;
    margin:3% 10% 0px 10%;
    display:flex;
    flex-direction: column;
    position:sticky;
}
.infoOne h1{
    margin-bottom:2%;
    font-size:2.5vw;
    margin-right: auto;
}
.h1Subli{
    background-color:#ff5019;
    width:35.2vw;
    height:5px;
    margin-top:-2%;
    margin-bottom:2%;
}
.divForm {
   
}
.divForm .form{
    display: flex;
    align-items: left;
    flex-direction: row;
    margin: 0px 0px 0px 0px;
    height: auto;
    width: 100%;
    font-size: 1.4vw;
}
.divForm input{
    width:80%;
    background-color:#f1f1f1;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    
}
.formTwo input:nth-child(8){
    width:100%;
}
.divForm label{
  margin-top:1%;
}
.btn-enviarTC{
    font-size:1.5vw;
    margin:4% auto 5% auto;
    color: #f05123;
    font-weight: 600;
    border-radius: 20px ;
    width: 15vw;
    height: 7vh;
    cursor:pointer;
}
.formOne{
  display: flex;
  flex-direction:column;
  width:56%;
}
.formTwo{
    display: flex;
    width:44%;
    height:auto;
    flex-direction:column;
}
.formTwo h4{
    font-size:1.5vw;
    left:52.5vw;
    top:13vw;
    position:absolute
}
.anexar{
    display: ${({ resumeConfirm }) => resumeConfirm ? 'none' : ''} !important;
}
.anexado{
    display: ${({ resumeConfirm }) => resumeConfirm ? '' : 'none'} !important;
}
.tamTextArea{
    height:40%;
    width:100%;
    background-color:#f1f1f1;
    border:none;
}
.infoOne p{
    font-size:1vw;
    margin-top:1%;
}
@media only screen and (max-width: 800px){
    [type="file"] + label {
     width:25vw;
     
    }

    .divForm input {
      width: 100%;
    }

    .infoOne h1{
        margin-bottom:2%;
        font-size:7vw;
        margin-right: auto;
        text-align:center;
    }
    .h1Subli{
        background-color:#ff5019;
        width:35.2vw;
        height:5px;
        margin:-2% auto auto auto;
    }
    .divForm .form{
        display: flex;
        align-items: left;
        flex-direction: column;
        margin: 2% 0px 0px 0px;
        height: auto;
        width: 100%;
        font-size: 5vw;
    }
    .formOne{
        display: flex;
        flex-direction:column;
        width:100%;
      }
      .formTwo{
          display: flex;
          width:100%;
          height:auto;
          flex-direction:column;
      }
      .btn-enviarTC{
        font-size:4vw;
        margin:8% auto 5% auto;
        border-radius: 20px;
        width: 30vw;
        height: 6vh;
    }
    .formTwo h4{
        font-size:4vw;
        left:27vw;
        top:119vw;
        position:absolute;
    }
    .infoOne p{
        font-size:3vw;
        margin-top:5%;
    }
    .tamTextArea{
        height:40%;
        width:100%;
        background-color:#f1f1f1;
        border:none;
    }
    .formTwo input:nth-child(8){
        width:80%;
    }
}
`;

const BodyTrabalheConosco = () => {
  const [resumeConfirm, setResumeConfirm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    info: '',
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
    setResumeConfirm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'file') {
        if (formData[key]) {
          data.append(key, formData[key], formData[key].name);
        }
      } else {
        data.append(key, formData[key]);
      }
    });
    
    const toastId = toast.loading("Aguarde...");

    try {
      const response = await apiFormClient.post('create_work_with_us?company=datamais', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      toast.dismiss(toastId);

      if (response.data.success) { // Ajuste de acordo com a resposta da sua API
        toast.success('Informações enviadas com sucesso!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          city: '',
          info: '',
          file: null,
        });
        setResumeConfirm(false);
      } else {
        toast.error('Falha ao enviar as informações. Tente novamente.');
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.error('Erro ao enviar o formulário: ', error);
      toast.error('Erro ao enviar o formulário.');
    }
  };
  

  return (
    <StyledBodyOneTC resumeConfirm={resumeConfirm}>
      <div className='infoOne'>
        <h1><i>Venha fazer parte da <b>Datamais!</b></i></h1>
        <div className='h1Subli'></div>
        <p>Estamos sempre a procura de novos talentos para integrar a nossa equipe. Envie seus dados e seu currículo pelo formulário abaixo.</p>
        <div className='divForm'>
          <form onSubmit={handleSubmit}>
            <div className='form'>
              <div className='formOne'>
                <label>Nome</label>
                <input name="name" value={formData.name} onChange={handleInputChange} placeholder='' />
                <label>E-mail</label>
                <input name="email" type='mail' value={formData.email} onChange={handleInputChange} />
                <label>Telefone</label>
                <input name="phone" type='number' value={formData.phone} onChange={handleInputChange} />
                <label>Cidade/UF</label>
                <input name="city" value={formData.city} onChange={handleInputChange} />
              </div>
              <div className='formTwo'>
                <h4 className={resumeConfirm ? 'anexado' : 'anexar'}>{resumeConfirm ? 'Arquivo Anexado' : 'Anexar Arquivo'}</h4>
                <label htmlFor="file" className='btn-1'>Procurar</label>
                <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }}/>
                <label htmlFor="file" className='btn-1'>Currículo</label>
                <label>Informações Adicionais</label>
                <textarea id="inputinfo" name="info" className='tamTextArea' value={formData.info} onChange={handleInputChange} type="text" maxLength="4000" />
              </div>
            </div>
            <div style={{textAlign: "center"}}>
              <button type="submit" className='btn-enviarTC'>Enviar</button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </StyledBodyOneTC>
  );
};

export default BodyTrabalheConosco;