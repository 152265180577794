import React, {useEffect} from "react";
import Header from "../../components/Header";
import TutorialDetails from "../../components/Body/TutorialDetails";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

const Tutorial = () => {

  const { id } = useParams();
    return(
        <>
        <Header /> 
        <TutorialDetails Id={id}/>
        <Footer />
        </>
    )
  }
export default Tutorial;
