import React, { useEffect } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import apiFormClient from '../../services/apiForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initPixelConvTrkr } from '../../services/facebookPixel';
import { logPixelPageView } from '../../services/facebookPixel';

import { useHistory } from 'react-router-dom';

import bread from '../../images/segments/bread.png';
import cheers from '../../images/segments/cheers.png';
import coffe from '../../images/segments/coffee.png';
import fast_food from '../../images/segments/fast-food.png';
import food_truck from '../../images/segments/food-truck.png';
import hamburger from '../../images/segments/hamburger.png';
import hot_dog from '../../images/segments/hot-dog.png';
import pizza from '../../images/segments/pizza.png';
import restaurant from '../../images/segments/restaurant.png';
import steak from '../../images/segments/steak.png';
import jimi from '../../images/segments/jimi.png';

const segments = [
  { name: 'Pizzaria', image: pizza, path: '/Pizzaria' },
  { name: 'Hamburgueria', image: hamburger, path: '/Hamburgueria' },
  { name: 'Lanchonete', image: hot_dog, path: '/Lanchonete' },
  { name: 'Cafeteria', image: coffe, path: '/Cafeteria' },
  { name: 'Bares e Pubs', image: cheers, path: '/Bar' },
  { name: 'Padarias e Confeitarias', image: bread, path: '/Padaria' },
  { name: 'Restaurantes', image: restaurant, path: '/Restaurante' },
  { name: 'Churrascarias', image: steak, path: '/Churrascaria' },
  { name: 'Praças de Alimentação', image: fast_food, path: '/PracaAlimentacao' },
  { name: 'Food Truck', image: food_truck, path: '/FoodTruck' },
  { name: 'Jimi Robô', image: jimi, path: '/JimiRobo' },
];

const segmentsMobile = [
  { name: 'Pizzaria', image: pizza, path: '/Pizzaria' },
  { name: 'Hamburgueria', image: hamburger, path: '/Hamburgueria' },
  { name: 'Lanchonete', image: hot_dog, path: '/Lanchonete' },
  { name: 'Cafeteria', image: coffe, path: '/Cafeteria' },
  { name: 'Jimi Robô', image: jimi, path: '/JimiRobo' },
  { name: 'Bares e Pubs', image: cheers, path: '/Bar' },
  { name: 'Padarias e Confeitarias', image: bread, path: '/Padaria' },
  { name: 'Restaurantes', image: restaurant, path: '/Restaurante' },
  { name: 'Churrascarias', image: steak, path: '/Churrascaria' },
  { name: 'Praças de Alimentação', image: fast_food, path: '/PracaAlimentacao' },
  { name: 'Food Truck', image: food_truck, path: '/FoodTruck' },
];

const Infoselection = () => {
  function getInfoFormClient() {
    let nameInput = document.querySelector("#inputname");
    let name = nameInput.value;
    let emailInput = document.querySelector("#inputemail");
    let email = emailInput.value;
    let phoneInput = document.querySelector("#inputphone");
    let phone = phoneInput.value;
    if (email === "") {
      notifyErrorSubmit()
    } else {
      const msg = `<h1 style="color: #5e9ca0;"><span style="color: #2b2301;">Contato do site</span></h1>
... <p>Nome: ${name}</p>
... <p>E-mail: <a href="mailto:${email}">${email}</a></p>
... <p>Telefone: ${phone}</p>`
      let encodeMsg = btoa(msg);

      const formClient = {
        "send_mail": {
          "from": "web@datamais.com.br",
          "to": "comercial@datamais.com.br",
          "from_name": "Site Comercial DataMais",
          "subject": "Formulário de contato do site",
          "msg": encodeMsg,
          "atachament": ""
        }
      }
      apiFormClient.post("index.php", formClient).then(result => {
        notifyConfirmSubmit()
      })
    }
  }

  const notifyConfirmSubmit = () => {
    toast.success('Enviado com sucesso!', {
      position: toast.POSITION.TOP_CENTER.RIGHT,
      autoClose: 3000,
      style: {
        textAlign: "left"
      }
    })
  }

  const notifyErrorSubmit = () => {
    toast.warn('Preencha os campos antes do envio!', {
      position: toast.POSITION.TOP_CENTER.RIGHT,
      autoClose: 3000,
      style: {
        textAlign: "left"
      }
    })
  }

  const history = useHistory();

  const redirectSegments = (path) => {
    if (path !== '') {
      history.push(path);
    }
  };

  return (
    <>
    <div class="container info_selection">
      <div class="row">
        <div class="col-sm-12 info_title">
          <span id='segmentos'></span>
          <p>Um sistema completo para o ramo de alimentação</p>
        </div>
        <div class="col-sm-12 info_sub_title">
          <p>Ferramentas completas para otimizar a gestão e descomplicar seu negócio, facilitando sua vida</p>
        </div>
        <div class="col-sm-12 info_title_options hidden_mobile">
          <p>Selecione seu Segmento!</p>
        </div>
        <div class="hidden_mobile">
          <div class="block_segmets">
            <div className='row' style={{'justifyContent': 'center'}}>
              {segments.map(segment => (
                <div class="col-sm-2 info_option">
                  <Link to={segment.path}>
                    <img className="image_segments" alt='img' src={segment.image}></img><a>{segment.name}</a>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div class="visible_mobile">
          <form>
            <div class="form-group info_form">
              <select id="inputsegment" class="form-control ls-custom-select info_input" onChange={e=> redirectSegments(e.target.value) }>
                <option selected class="info_form_option">Selecione seu Segmento</option>
                {segmentsMobile.map(segment => (
                  <option class="info_form_option" value={segment.path}>{segment.name}</option>
                ))}
              </select>
            </div>
            {/* <div class="info_solucao_button">
              <Link to="/LP">Ver Solução</Link>
            </div> */}
            <div className='hrdouble' />
            <div class="info_title">
              <p>Entre em Contato</p>
            </div>
            <div class="form-group info_form">
              <input type="text" class="form-control  info_input" id="inputname" placeholder="Nome" required />
            </div>
            <div class="form-group info_form">
              <input type="email" class="form-control  info_input" id="inputemail" placeholder="E-mail" required />
            </div>
            <div class="form-group info_form">
              <input type="tel" class="form-control  info_input" id="inputphone" placeholder="Telefone" required />
            </div>
          </form>
          <button
            class="info_form_button"
            onClick={() => {
              getInfoFormClient()
            }}>
            Enviar</button>
          <ToastContainer />
        </div>
      </div>
    </div>
      <br/>
      <br/>   
      <div class="col-sm-12 info_title_options info_title_options_integrations">
        <p>Parceiros Oficiais Integrados!</p>
      </div>
    
    <div class="container">
      <div class='card'>
        <img src="https://storage.googleapis.com/partnerhub_partner_badges_prod/D_652071fc490a8e0019f46be4.png"/>
        <h3>Stone</h3>
        <div class='cardP'>
          <p>
          Lançamento de pedidos, fechamento de contas, impressão de tickets e cobranças tudo por um só lugar. Revolucione suas vendas com nosso parceiro Stone.
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Infoselection;
