import React, {useState} from 'react';
import styled from 'styled-components';
import logo from '../../../../images/logo_escrita.png';
import mockup from '../../../../images/lp_datamais/mockupMobile.jpg';
import wavebot from '../../../../images/lp_datamais/wavebot.svg';
import StyledCardInfo from './style';
import ContactModal from '../../../ContactModal';

const solutions = [
  {
    title: 'Autoatendimento',
    description:
      'Apenas apontando o celular para um QrCode, o cliente consegue enviar o pedido direto para a cozinha ou para a copa.',
  },
  {
    title: 'Automação na Pesagem',
    description:
      'Assim que o prato é pesado, automaticamente é adicionado na comanda eletrônica. Pensado da melhor forma para tornar o seu trabalho mais dinâmico.',
  },
  {
    title: 'Comanda Digital',
    description:
      'Seu garçom estará apto a anotar os pedidos.\n\nCom apenas alguns toques no celular o pedido é enviado para cozinha ou copa, sem precisar se deslocar.',
  },
  {
    title: 'Frente de Caixa',
    description:
      'Evite longas filas!\n\nCom apenas 3 toques feche a conta do cliente e imprima o comprovante.',
  },
  {
    title: 'Delivery',
    description:
      'Receba pedidos por multicanais.\n\nCalcule o frete de acordo com o CEP.\n\nEnvie o pedido direto para a produção.',
  },
  {
    title: 'Integração com iFood',
    description:
      'Todos seus pedidos feitos na plataforma também são gerenciáveis pelo Datamais, trazendo praticidade e precisão nos seus dados.',
  },
  {
    title: 'Cadastro de Insumos e Receitas',
    description:
      'Tenha controle do seu estoque!\n\nSaiba em tempo real quais itens não atingem a margem de lucro desejada.',
  },
  {
    title: 'Suporte Personalizado',
    description:
      'Nossa equipe está de prontidão para solucionar qualquer problema que possa surgir.\n\nTodos os dias das 08:00 às 00:00, incluindo sábados, domingos e feriados.',
  },
  {
    title: 'Integração com Accon',
    description:
      'Tenha um cardápio online para seu Delivery com a gestão completa do Datamais.',
  },
  {
    title: 'Venda de Tickets',
    description:
      'Emissor de Tickets para Pubs, Baladas e Food Parks.\n\nControle a entrada com pulseira numerada por cliente.',
  },
  {
    title: 'Produção',
    description:
      'Módulo para o controle de produção de panificadoras e indústrias de alimentos. \n\nRegistro de pedidos diários e recorrentes dos seus clientes. \n\nUnifique os pedidos em apenas uma NFE.',
  },
];


const CardInfo = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e) => {
      setModalIsOpen(true);
      e.preventDefault();
  };

  return (
    <StyledCardInfo>
      <h1>Conheça Nossas Principais Soluções</h1>
      <div className='cardLP'>
        {solutions.map((solution, index) => (
          <div className='card' key={index}>
            <h3>{solution.title}</h3>
            <div className='cardP'>
              <p>{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='cardLP-Mobile'>
        <ul>
          {solutions.map((solution, index) => (
            <li key={index}>
              <span>{solution.title}</span>
            </li>
          ))}
          <li>
            <span>E muito mais.</span>
          </li>
        </ul>
      </div>
      <div className='divh2'>
        <h2>Datamais é o sistema ideal para o seu negócio!</h2>
      </div>
      <div className='div-btn-contact-bodyLPOne'>
        <div className='btn-contact-bodyLPOne button-animation' onClick={e=>openModal(e)}>
          <a href='https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0'>
            Fale com nossa equipe!
          </a>
        </div>
      </div>
      <img src={wavebot} className='wavebot' alt='wave' />
      <img src={mockup} className='mockupMobile' alt='First img' />
      <ContactModal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} isExitIntent={'E'}/>
    </StyledCardInfo>
  );
};

export default CardInfo;
