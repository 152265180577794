import './App.css';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './GlobalStyle';

function App() {

  return (
    <div className="App">  
      <GlobalStyle /> 
      <Routes />
    </div>
  );
}

export default App;
