import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BodyLP from "../../components/Body/LP";
import CardInfo from "../../components/Card/LP/CardOne";
import CardInfoClient from "../../components/Card/LP/CardTwo";
import ContactLP from "../../components/BodyContact/LP";
import { useLocation } from "react-router-dom";
  


const LP = () => {

    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath]);
  
  
    return(
        <>
        <Header theme="white"/> 
        <BodyLP />
        <CardInfo />
        <ContactLP />
        <Footer />
        </>
    )
  }
export default LP;

 