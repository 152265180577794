import styled from 'styled-components';

const StyledCardInfo = styled.div`
  .cardLP-Mobile {
    display: none;
  }

  .mockupMobile {
    display: none;
  }

  .wavebot {
    display: none;
  }

  .logoDatamais {
    margin-left: 42%;
    margin-bottom: 2%;
  }

  h1 {
    margin-top: 2%;
    text-align: center;
    color: #fe5b13;
    font-weight: 800;
    font-size: 55px;
  }

  .divh1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divh1 h2 {
    color: #fe5b13;
    font-weight: 800;
  }

  .cardLP {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4% 4% 0% 4%;
  }

  .card {
    height: 380px;
    width: 30%;
    max-width: 300px;
    box-shadow: 0 0 1em #ccc;
    margin: 2% 1%;
    border-radius: 18px !important;

    h3 {
      height: auto;
    }

    p {
      margin-top: 10px;
    }
  }

  .cardP {
    margin: auto auto 0px auto;
    color: #000;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    height: 75%;
    width: 100%;
    padding: 0% 10% 0% 10%;
    white-space: pre-wrap;
  }

  .div-btn-contact-bodyLPOne {
    width: 35%;
    margin: auto;
  }

  .btn-contact-bodyLPOne {
    position: sticky;
    width: 100%;
    margin: auto;
    height: 8vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#fefefe, #eeeeee, #d0d0d0, #767474);
    border-radius: 50px;
    box-shadow: inset 0px -15px 1em #ccc;
    white-space: nowrap;
    z-index: 15 !important;
  }

  .btn-contact-bodyLPOne a {
    align-content: center;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #fe5b13;
    font-weight: bold;
    font-size: 2.8vw;
  }

  .btn-contact-bodyLPOne a:hover {
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
  }

  h3 {
    text-align: center;
    color: #fe5b13;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 8%;
    margin-bottom: 0px;
    height: 10px;
  }


  @media only screen and (max-width: 1500px) {
    .depoiment iframe {
      width: 80vw !important;
      height: 80vh !important;
    }

    .depoiment{
      padding: 10px;
    }

  }

  @media only screen and (max-width: 1050px) {
    .cardP {
      padding: 20px;
    }

    .card p{
      font-size: 23px;
    }
  }

  @media only screen and (max-width: 800px) {
    .div-btn-contact-bodyLPOne {
      width: 100%;
      height: 8vh;
      margin-bottom: 40px;
    }

    .card {
      width: 400px;
    }

    .btn-contact-bodyLPOne {
      height: 20vw !important;
      width: 80%;
    }

    .btn-contact-bodyLPOne a {
      font-size: 5.5vw;
    }

    .divh1 {
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
    }

    .divh1 h2 {
      color: #fe5b13;
      font-weight: 800;
    }

    .mainImgLPLogo {
      margin: 25% auto 10% 5%;
      width: 90%;
    }

    h1 {
      margin-top: 10%;
      text-align: center;
      color: #fe5b13;
      font-weight: 800;
      font-size: 40px;
    }


    .cardLP .card{
      margin: 30px 0px 30px 0px !important;
    }

    ul li {
      color: #fe5b13;
      font-size: 28px;
      margin: 5% 0px 5% 0px;
    }

    ul li span {
      color: #404041;
      font-weight: 600;
    }

    h3 {
      text-align: center;
      color: #fe5b13;
      font-weight: 600;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 10%;
    }

    .mockupMobile {
      width: 100%;
      margin-bottom: -20%;
      display: inline;
      z-index: 2 !important;
    }

    .wavebot {
      transform: scale(-1);
      margin-bottom: -40%;
      z-index: 2 !important;
      position: sticky;
      display: inline;
    }

    .divh1 h1{
      font-size: 25px;
    }

    .text-info-jimi p{
      font-size: 22px !important;
    }

    .div-btn-contact-bodyLP{
      height: 80px !important;
      width: 80% !important;

      .btn-contact-bodyLP{
        height: 70px;
      }
    }
    
    .btn-contact-bodyLP a{
      font-size: 25px !important;
    }

    .jimi-list-attr{
      width: 100% !important;
    }

    .jimi-list-attr .text-list p {
      font-size: 17px !Important;
    }

    .jimi-list-attr .img-list img{
      width: 100% !important;
    }
    
    .jimi-list-attr .img-list{
      padding: 0px !important;
    }

    .jimi-list-attr div {
      height: 60px !important;
    } 

    .specs-jimi {
      display: none;
    }

    .depoiment{
      padding: 3px !important;
      margin-bottom: 40px;
    }

    .depoiment p {
      font-size: 20px !important;
    }

    .depoiment iframe {
      height: 230px !Important;
    }

    .text-info-jimi h1{
      font-size: 25px;
      margin: 5px !important;
    }

    .specs-jimi2 p{
      font-size: 10px !important;
    }
    
    .specs-jimi2 .attrs-jimi{
      width: 90% !important;
    }

    .specs-jimi2 .attrs-jimi .attr{
      margin-top: 20px;
      display: block !important;
      text-align: center !important;

      box-shadow: 0px 0px 10px #ccc;

      p {
        box-shadow: unset !important;
        padding: 0px;
        margin: 0px;
        margin-top: -40px;
        font-size: 20px !important;
        font-weight: 600;
      }
    }
  }

  .text-info-jimi{
    background: #ff5019;
    margin: 30px 0px 30px 0px;
    padding: 50px 0px 50px 0px;
    p {
        text-align: center;
        color: #fff;
        font-size: 50px;
      }
  }

  .div-btn-contact-bodyLP{
    white-space: nowrap;
    width: 35%;
    margin: 0 auto;
}

.btn-contact-bodyLP{
    height: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 50px;
    box-shadow: inset 0px 2px 20px 0px #c3c3c3;
    margin-bottom: 5%;
    background: linear-gradient(#fefefe,#eeeeee,#d0d0d0,#767474);
}

.btn-contact-bodyLP a{
    align-content: center;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #fe5b13;
    font-size: 2.5vw;
    font-weight: bold;
}

.btn-contact-bodyLP a:hover{
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
}

.jimi-list-attr{
  width: 80%;
  margin: 30px auto;

  div {
    height: 70px;
  }

  .item {
    margin: 20px 0px 20px 0px;
  }
  
  .text-list {
    background: #d0d0d0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 30px;

    p{
      margin: 0px;
      color: #212529;
      font-weight: 700;
    }
  }

  .img-list {
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 150px;
    }
  }
}

.specs-jimi{
  background: #ff5019;
  padding: 20px 0px 20px 0px;

  h1 {
    color: #fff;
    margin-bottom: 50px;
  }

  .row {
    width: 95%;
    margin: 0 auto;

    .col-sm-6:nth-child(1){
      display: flex;
      align-items: center;
    }
  }

  img {
    width: 100%;
  }
}

.attrs-jimi { 

  width: 30%;

  .attr{
    background: #fff;
    padding: 20px;
    margin: 20px 0px 20px 0px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    height: 200px;

    p{
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 35px;
      color: #212529 !important;
      font-weight: 700;
    }

    svg{
      font-size: 70px;
      color: #ff5019;
    }
  }
}

.depoiment{
  text-align: center;
  padding: 50px;

  p {
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    color: #212529;
    font-weight: 700;
  }

  iframe{
    max-width: 100%;
    width: 1536px;
    height: 864px;
    box-shadow: 0px 0px 20px 10px #ccc;
    border-radius: 10px;
  }
}

.specs-jimi2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .attrs-jimi {
      width: 40%;
    }

    .attr p{
        font-size: 2.6vw;
        box-shadow: 0px 0px 10px #ccc;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-left: 30px;
        padding: 20px;
        color: #212529 !important;
      }
    }

    svg {
      font-size: 90px !important;
      color: ##ff5019 !important;
    }
}


`;

export default StyledCardInfo;