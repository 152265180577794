import React, { useEffect } from "react";
import Footer from "../../../components/Footer";

import BodyLPJimi from "../../../components/Body/LPJimi";

import CardInfoJimi from "../../../components/Card/LP/CardThree";
import ContactLP from "../../../components/BodyContact/LP";
import { useLocation } from "react-router-dom";
  


const JimiRobo = () => {

    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath]);
  
  
    return(
        <>
        <BodyLPJimi />
        <CardInfoJimi />
        <ContactLP />
        </>
    )
  }
export default JimiRobo;

 