import React, { useState, useEffect } from 'react';
import { BsTelephoneFill } from 'react-icons/bs';
import styled from 'styled-components';
import apiFormClient from '../../../services/apiForm';
import ammyy from '../../../images/ammyy.png';
import rust from '../../../images/rust.png';
import logoAutoatednimento from '../../../images/automacao_icon.ico';
import qrcodeapp from '../../../images/qr-code-app.svg';
import anydesk from '../../../images/anydesk.png';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initPixelSuporte } from '../../../services/facebookPixel';
import { logPixelPageView } from '../../../services/facebookPixel';
import { initPixelConvTrkr } from '../../../services/facebookPixel';
import { initGA } from '../../../services/googleAnalytics';
import { IoChatbubbles } from "react-icons/io5";
import { Link } from 'react-router-dom';

const StyledBodySuporte = styled.div`
[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
  [type="file"] + label {
    width:22%;
    background: #f15d22;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    padding: 2%;
    position: relative;
    transition: all 0.3s;
    
    &:hover {
      background-color:(#f15d22, 10%);
    }
    
    &.btn-1 {
      background-color: #ff6e0c;
      box-shadow: 0 6px #ff5415;

  
      &:hover {
        box-shadow: 0 4px darken(#f79159, 10%);
        top: 3px;
      }
    }
  }
    .container{
      margin-top:5%;
   }
   .container h1{
           font-size:2.5vw;
           margin-right: auto;
           font-weight:600;
   }
   .container p{
       margin-top:3%;
       font-size:1.3vw;
   }
   .menuPag, .containerAppMenu{
       width:100%; 
       height:auto;
       display:flex;
       flex-direction:row;
       justify-content: end;
       z-index: 10000 !important;

       .attLogo {
        width: 50% !important;
        height: 150px;
        max-width: 220px;
        display: flex;
        justify-content: end;
        padding: 0px 20px 0px 30px;

        img{
            width: 80%;
            height: fit-content;
        }
       }
   }

   .menuPag {
    align-items: center;
   }

   .containerAppMenu {
    margin: 20px 0px;
   }

   .contato {
    z-index: 10000 !important;
   }

   .containerAppMenu a, .contato a {
    color: #ff5019;
    z-index: 10000 !important;
   }

   .containerAppMenu p {
    margin: opx;
    font-size: 22px;
   }

   .containerAppMenu .baixarApp {
    width: 40% !important;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .containerAppMenu .qrcode {
    width: 50% !important;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media only screen and (max-width: 1400px){
        width: 60% !important;
    }

    @media only screen and (max-width: 1200px){
        p, a {
            font-size: 18px !important;
        }
    }

    @media only screen and (max-width: 800px){
        p, a {
            font-size: 20px !important;
        }
        
    }

    p:first-child {
        width: 100%;
    }

   }

   .containerAppMenu .qrcode{
    p{
        margin-top: 0px !important;
        margin-bottom: 6px !important;
    }
    img{
        width: 100px;
        margin-left: 10px;
        float: right;
    }
   }

   h3{
       margin:auto;
       font-size:2vw;
   }
   .contato{
        width:60%;
        heigth: 150px;
        display:flex;
        flex-direction:column;
        align-items: center;
   }
   .contato p{
       font-size: 20px;
   }
 
   .iconsContact{
        margin-top:-13px;
   }
   .contato h5{
       color:#44447;
   }
  .divicons{
      width:10vw;
      text-align: right;
  }
   .iconsSuporte{
        display:flex;
        justify-content:flex-end;
        align-items:center;
        width:50%;
        cursor:pointer;
   }
   .iconsSuporte h5{
        color:#44447;
        margin-right:10px;
        white-space:nowrap;
    }
    .iconsSuporte a{
        color:#0d6efd;
    }
   .icons{
       width:26%;
       border: solid 2px;
       border-radius:10px;
       margin: 0px 5px 0px;
       border-color: #000;
   }

   .h1Subli{
       background-color:#ff5019;
       width:28.3vw;
       height:5px;
       margin-top:-0.7%;   
       margin-bottom:2%; 
   }

   .cardSuporte{
       width:100%;
       display:flex;
       flex-direction:row no-wrap;
       justify-content: space-around;
       margin-top:3%;
   }
   .card{
       height: 350px;
       width:25%;
       box-shadow: 0 0 1em #ccc;
       margin-bottom: 5%;
       border-radius: 18px !important;
   }
   .card h4{
       margin:5% auto;
       color:#ff5216;
       text-align:center;
       font-weight:600;
   }
   .card p{
        font-size:15px;
        margin-top:25%;
        color:red;
        white-space:nowrap;
        text-align: center;
    }
   .titleCard{
       margin-left:5%;
       margin-right:5%;
       height:20%;
   }
   .infoCard{
        margin:8% auto auto auto;
   }
   .iconsCard{
       margin-right:10px;
   }
   .subtitulo{
       display:flex;
       margin-top:2%;
   }
   h6{
        margin:5% auto;
        text-align:center;
        font-weight:600;
   }
   .divForm{
       width:80%;
       margin:3% auto 3% auto;
   }
   .divForm .form{
    display: flex;
    align-items: left;
    flex-direction: row;
    margin: 0px 0px 0px 0px;
    height: auto;
    width: 100%;
    font-size: 1.4vw;
}
.divForm input{
    width:80%;
    background-color:#f1f1f1;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    
}
.formTwo input:nth-child(8){
    width:100%;
}
.divForm label{
  margin-top:1%;
}
.btn-enviarTC{
    font-size:1.5vw;
    margin:15% auto 5% auto;
    color: #f05123;
    font-weight: 600;
    border-radius: 20px ;
    width: 15vw;
    height: 7vh;
    cursor:pointer;
}
.divButton{
    diplay:flex;
    justify-content:center;
    margin:auto;
    width:18%;
}
.formOne{
  display: flex;
  flex-direction:column;
  width:56%;
}
.formTwo{
    display: flex;
    width:44%;
    height:auto;
    flex-direction:column;
}
.formTwo h4{
    font-size:1.5vw;
    left:52.5vw;
    top:13vw;
    position:absolute
}
.anexar{
    display: ${({ imgConfirm }) => imgConfirm ? 'none' : ''} !important;
}
.anexado{
    display: ${({ imgConfirm }) => imgConfirm ? '' : 'none'} !important;
}
@media only screen and (max-width: 1200px) and (min-width: 1001px) {
    .infoCard h5{
        margin:5% auto;
        text-align:center;
        font-size:1.5vw;
   }
   .iconsCard{
       margin-right:10px;
   }
   .subtitulo{
       margin-top:2%;
   }
   
}   
@media only screen and (max-width: 1000px) and (min-width: 801px) {
    .card p{
        font-size:10px;
        margin-top:45%;
        color:red;
        white-space:nowrap;
   }
   .infoCard h5{
    margin:5% auto;
    text-align:center;
    font-size:1.5vw;
}
.iconsCard{
   margin-right:10px;
}
.subtitulo{
   margin-top:2%;
}
}

@media only screen and (max-width: 800px){
    [type="file"] + label {
     width:25vw;
     
    }
    .titleSuporte{
        display: block !important;
    }

    .iconsSuporte {
        margin: 20px 0px 10px 0px !important;
        img{
            width: 40px !important;
        }
    }
    
    .containerAppMenu .baixarApp {
        width: 100% !important;

        p {
            text-align: center !important;
            margin: 10px;
        }
    }

    .containerAppMenu {
        .attLogo{
            display: none;
        }

        .qrcode{
            display: block;
            width: 100% !important;

            p:nth-child(2), img{
                display: none;
            }

            p{
                display: grid;
                width: 100%;
                font-size: 20px;
                text-align: center;
            }
        }

    }

    .divForm input {
        width: 100%;
    }

    .container h1{
        margin-bottom:2%;
        font-size:6vw;
        margin-right: auto;
        
    }
    .h1Subli{
        background-color:#ff5019;
        width:68vw;
        height:5px;
        margin-top:-2%;
    }
    .divForm .form{
        align-items: left;
        flex-direction: column;
        margin: 2% 0px 0px 0px;
        height: auto;
        width: 100%;
        font-size: 5vw;
    }
    .formOne{
        display: flex;
        flex-direction:column;
        width:100%;
      }
      .formTwo{
          width:100%;
          height:auto;
          flex-direction:column;
      }
      .btn-enviarTC{
        font-size:4vw;
        border-radius: 20px;
        width: 30vw;
        height: 6vh;
    }
    .divButton{
        justify-content:center;
        margin:auto;
        width:35%;
        margin-top:4%;
    }
    .formTwo h4{
        font-size:4vw;
        left:27vw;
        top:119vw;
        position:absolute;
    }
    .infoOne p{
        font-size:3vw;
        margin-top:5%;
    }
    .tamTextArea{
        height:40%;
        width:100%;
        background-color:#f1f1f1;
        border:none;
    }
    .formTwo input:nth-child(8){
        width:80%;
    }
    .menuPag, .containerAppMenu{
        flex-direction:column;
        width:100%;
    }
    .contato{
        width:100%;
        heigth:15px;
        display:block;
        flex-direction:row;
        align-items: center;
   }
   .contato p{
       font-size: 18px;
   }
 
   .iconsContact{
        margin-top:13px;
   }
   .contato h5{
       color:#44447;
   }
  .divicons{
      width:200px;
  }
 
   .iconsSuporte{
        display:flex;
        margin:auto;
        align-items:center;
        width:70%;
        cursor:pointer;
       
   }
   .icons{
       width:32%;
   }
   h3{
       font-size:5vw;
   }
   .cardSuporte{
    width:100%;
    flex-direction:column;
    margin-top:3%;
}
.card{
    height: 300px;
    width:80%;
    box-shadow: 0 0 1em #ccc;
    margin: 3% auto 3% auto;
    border-radius: 18px !important;
}
.card h4{
    margin:5% auto;
    color:#ff5216;
    text-align:center;
    font-weight:600;
}
.titleCard{
    margin-left:5%;
    margin-right:5%;
    height:30%;
}
.infoCard{
     margin:8% auto auto auto;
}
.iconsCard{
    margin-right:10px;
}
.subtitulo{
    display:flex;
    margin-top:2%;
}
h6{
     margin:5% auto;
     text-align:center;
     font-weight:600;
}
}

.titleSuporte{
    display: flex;
    justify-content: space-between;
}

.webChat {
    margin: 20px 0px 30px 0px;
}

.webChat h5{
    display: flex;
    align-items: center;
}

.webChat a {
    text-decoration: none;
    color: #212529;
}

`;

const BodySuporte = () => {
    const [cardFile, setCardFile] = useState();
    const [cardFileName, setCardFileName] = useState();

    useEffect(() => {
        initPixelConvTrkr();
        logPixelPageView();
        initPixelSuporte();
    }, [])

    useEffect(() => {
        initGA();
    }, [])

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [info, setInfo] = useState('');
    const [file, setFile] = useState(null);
    const [imgConfirm, setImgConfirm] = useState(false);
  
    const handleUploadFile = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile && selectedFile.size > 4000000) { // Maior que 4MB
        toast.error("O arquivo não pode exceder 4MB!");
        return;
      }
      setFile(selectedFile);
      setImgConfirm(true);
    };
  
    const loadResume = async () => {
      if (!email) {
        toast.warn('Por favor, preencha todos os campos obrigatórios.');
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('info', info);
      const toastId = toast.loading("Aguarde...");
      try {
        const response = await apiFormClient.post("create_suport?company=datamais", formData);
        toast.dismiss(toastId);
        if (response.data.success) {
          toast.success("Enviado com sucesso!");
          setName('');
          setEmail('');
          setPhone('');
          setInfo('');
          setFile(null);
          setImgConfirm(false);
        } else {
          toast.error("Falha ao enviar. Por favor, tente novamente.");
        }
      } catch (error) {
        toast.dismiss(toastId);
        console.error("Erro ao enviar: ", error);
        toast.error("Erro ao enviar. Por favor, tente novamente.");
      }
    };


    return (
        <StyledBodySuporte imgConfirm={imgConfirm}>
            <div className="container">
                <div className='titleSuporte'>
                    <div>
                        <h1>Precisando de Suporte?</h1>
                        <div className="h1Subli" />
                    </div>
                    <div className="iconsSuporte">
                        <h5><i>Suporte Remoto:</i></h5>
                        <div className="divicons">
                            <a target="_blank" href="https://onedrive.live.com/?id=BFED2C5F463ADE0A%2176094&resid=BFED2C5F463ADE0A%2176094&authkey=%21AO7llSZBrzU3MZw&cid=bfed2c5f463ade0a" rel="noreferrer">
                                <img
                                    src={rust}
                                    alt="IconAmmyy"
                                    className="icons"
                                />
                            </a>
                            <a target="_blank" href="https://onedrive.live.com/?authkey=%21ADjH9I1OMzusVic&id=BFED2C5F463ADE0A%2176062&cid=BFED2C5F463ADE0A&parId=root&parQt=sharedby&o=OneUp" rel="noreferrer">
                                <img
                                    src={anydesk}
                                    alt="Icon"
                                    className="icons"
                                />
                            </a>
                            <a target="_blank" href="https://onedrive.live.com/?authkey=%21AH5NUMKXl29OZH0&id=BFED2C5F463ADE0A%2123182&cid=BFED2C5F463ADE0A&parId=root&parQt=sharedby&o=OneUp" rel="noreferrer">
                                <img
                                    src={ammyy}
                                    alt="IconAmmyy"
                                    className="icons"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="menuPag">
                    <div className="contato">
                        <p>Nossa equipe está de prontidão para ajudar você.
                            <br></br>
                            Nosso plantão, das 18:00h às 00:00h, atenderá as emergências para que sua empresa não pare.</p>
                        <p style={{textAlign: 'left', width: '100%'}}> <a href="../Tutoriais" style={{fontSize: '18px'}}>Tutorial Datamais</a> <br/> Dicas, vídeos, instruções...</p>
                    </div>
                    <div className='containerAppMenu'>
                        <div className='attLogo'>
                            <img src={logoAutoatednimento}/>
                        </div>
                        <div className='qrcode'>
                            <p>Baixe o app de Atendimento <a href="https://app.datamais.com.br/app/atual/" target='_blank'>clicando aqui</a></p>
                            <p>ou escaneando o QR Code</p>
                            <img src={qrcodeapp} />
                            <p><a href="https://app.datamais.com.br/app/" target='_blank' style={{fontSize: '18px'}}>Versões anteriores</a></p>
                        </div>
                    </div>
                </div>
                <br />
                <div className="subtitulo">
                    <h3>Veja abaixo os horários e modalidades de suporte</h3>
                </div>
                <div className="cardSuporte">
                    <div className="card">
                        <div className="titleCard">
                            <h4>Segunda à Sexta</h4>
                        </div>
                        <div className="infoCard">
                            <h5>das 08:00 às 18:00 horas</h5>
                            <h5> <BsTelephoneFill
                                className="iconsCard"
                                color="#ff5216"
                                size={30} />(47)<b>3382-7212</b></h5>
                            <h5 className='webChat'>
                                <a target="_blank" href="https://datamais.bitrix24.com.br/online/atendimento-datamais">
                                <IoChatbubbles 
                                className="iconsCard"
                                color="#ffa801"
                                size={30} 
                                />
                                <b>Chat Online</b>
                                </a>
                            </h5>
                            <h6>Opção 1</h6>
                        </div>
                    </div>
                    <div className="card">
                        <div className="titleCard">
                            <h4>Segunda à Sexta</h4>
                        </div>
                        <div className="infoCard">
                            <h5>das 18:01 às 00:00 horas</h5>
                            <h5><BsTelephoneFill
                                className="iconsCard"
                                color="#ff5216"
                                size={30} />(47)<b>98819-6899</b></h5>
                            <h5 className='webChat'>
                                <a target="_blank" href="https://datamais.bitrix24.com.br/online/atendimento-datamais">
                                <IoChatbubbles 
                                className="iconsCard"
                                color="#ffa801"
                                size={30} />
                                <b>Chat Online</b>
                                </a>
                            </h5>
                            <h6>Plantão</h6>
                            <p>Suporte técnico de emergência</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="titleCard">
                            <h4>Sábados, Domingos e Feriados </h4>
                        </div>
                        <div className="infoCard">
                            <h5>das 08:00 às 00:00 horas</h5>
                            <h5><BsTelephoneFill
                                className="iconsCard"
                                color="#ff5216"
                                size={30} />(47) <b>98819-6899</b></h5>
                            <h5 className='webChat'>
                                <a target="_blank" href="https://datamais.bitrix24.com.br/online/atendimento-datamais">
                                <IoChatbubbles 
                                className="iconsCard"
                                color="#ffa801"
                                size={30} />
                                <b>Chat Online</b>
                                </a>
                            </h5>
                            <h6>Plantão</h6>
                            <p>Suporte técnico de emergência</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subtitulo">
                <h3>Se preferir nos envie um E-mail!</h3>
            </div>

            <div className='divForm'>
                <div className='form'>
                <div className='formOne'>
                <label>Nome</label>
                <input value={name} onChange={(e) => setName(e.target.value)} id="inputname" placeholder='' />
                <label>E-mail</label>
                <input type='mail' value={email} onChange={(e) => setEmail(e.target.value)} id="inputemail" />
                <label>Telefone</label>
                <input value={phone} type="number" onChange={(e) => setPhone(e.target.value)} id="inputphone" />
                </div>
                <div className='formTwo'>
                <label htmlFor="file" className='btn-1'>{imgConfirm ? 'Imagem anexada!' : 'Anexar Imagem:'}</label>
                <input id="file" type="file" onChange={handleUploadFile} style={{ display: 'none' }} />
                <label htmlFor="file">Procurar</label>
                <label>Informações Adicionais</label>
                <textarea value={info} onChange={(e) => setInfo(e.target.value)} id="inputinfo" className='tamTextArea' type="text" maxLength="4000" />
                </div>
                </div>
                <div className='divButton'>
                    <button onClick={loadResume} className='btn-enviarTC'>
                        Enviar
                    </button>
                </div>
                <ToastContainer />
            </div>
        </StyledBodySuporte>

    )
}

export default BodySuporte