import React, { useState, useEffect } from 'react';
import apiFormClient from '../../../services/apiForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowForward, IoMdArrowBack, IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import {
    Box,
    Card,
    Container,
    Divider,
    Grid2,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Stack
} from '@mui/material';


const TutorialList = ({ ...rest }) => {

    const [groups, setGroups] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [tutorials, setTutorials] = useState({});
    const [pesquisaText, setPesquisaText] = useState("");
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(pesquisaText);


    const filterAllTutiriais = () => {
        const toastId = toast.loading("Buscando tutoriais...");
        apiFormClient.get('get_all_groups?company=datamais')
            .then(response => {
                const groupsData = response.data;
                if (!groupsData || groupsData.length === 0) {
                    toast.dismiss(toastId);
                    console.log("No groups data found");
                    return;
                }

                apiFormClient.get('get_all_tutorials?company=datamais')
                    .then(tutorialsResponse => {
                        const tutorialsData = tutorialsResponse.data;
                        const tutorialsMap = tutorialsData.reduce((acc, tutorial) => {
                            if (!acc[tutorial.ID_DM001]) {
                                acc[tutorial.ID_DM001] = [];
                            }
                            acc[tutorial.ID_DM001].push(tutorial);
                            return acc;
                        }, {});

                        const groupsMap = groupsData.reduce((acc, group) => {
                            acc[group.ID_DM001] = { ...group, children: [] };
                            return acc;
                        }, {});

                        Object.values(groupsMap).forEach(group => {
                            if (group.ID_DM001_PAI !== 0 && groupsMap[group.ID_DM001_PAI]) {
                                groupsMap[group.ID_DM001_PAI].children.push(group);
                            }
                        });

                        const topLevelGroups = Object.values(groupsMap).filter(group =>
                            group.ID_DM001_PAI === 0 && (tutorialsMap[group.ID_DM001] || group.children.some(child => tutorialsMap[child.ID_DM001]))
                        );

                        topLevelGroups.sort((a, b) => a.NR_ORDEM - b.NR_ORDEM);

                        setAllGroups(topLevelGroups);
                        setGroups(topLevelGroups);
                        setTutorials(tutorialsMap);
                        toast.dismiss(toastId);
                    })
                    .catch(error => console.log("Error fetching tutorials data", error), toast.dismiss(toastId));
            })
            .catch(error => {
                toast.dismiss(toastId);
                console.log("Error fetching groups data", error);
            });
    }

    useEffect(() => {
        filterAllTutiriais();
    }, []);

    const handleSearch = (event) => {
            if (!pesquisaText) {
                filterAllTutiriais();
            } else {
                apiFormClient.post('filter_tutorials?company=datamais', {
                    DS_PESQUISA_TUTORIAL: debouncedSearchTerm
                })
                    .then(response => {
                        const filteredTutorials = response.data;
                        const tutorialsMap = {};
                        const filteredGroupIds = new Set();

                        filteredTutorials.forEach(tutorial => {
                            if (!tutorialsMap[tutorial.ID_DM001]) {
                                tutorialsMap[tutorial.ID_DM001] = [];
                            }
                            tutorialsMap[tutorial.ID_DM001].push(tutorial);
                            filteredGroupIds.add(tutorial.ID_DM001);
                        });

                        setTutorials(tutorialsMap);

                        const filterSubgroups = (subgroups) => {
                            return subgroups.filter(child =>
                                filteredGroupIds.has(child.ID_DM001) ||
                                (child.children && child.children.length > 0 && filterSubgroups(child.children).length > 0)
                            );
                        };

                        const filteredGroups = allGroups.map(group => {
                            const filteredChildren = filterSubgroups(group.children);
                            return filteredGroupIds.has(group.ID_DM001) || filteredChildren.length > 0
                                ? { ...group, children: filteredChildren }
                                : null;
                        }).filter(group => group !== null);

                        setGroups(filteredGroups);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        
    };

    const handleViewGroup = (groupId) => {
        setSelectedGroup(groupId);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(pesquisaText);
        }, 400);
    
        return () => {
            clearTimeout(handler);
        };
    }, [pesquisaText]);

useEffect(() => {
    if (debouncedSearchTerm) {
        handleSearch();
    } else {
        filterAllTutiriais();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [debouncedSearchTerm]);

    const [selectedSubgroup, setSelectedSubgroup] = useState(null);
    const handleViewSubGroup = (groupId) => {
        setSelectedSubgroup(groupId);
    }

    return (
        <Container maxWidth="lg" sx={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        }}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                fullWidth
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    textAlign: { xs: 'center', sm: 'left' },
                }}
            >
                <Typography variant="h1" component="h1" gutterBottom>
                    Tutoriais
                </Typography>
                <Button
                    href="https://datamais.com.br/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: 'white',
                        backgroundColor: '#ff5019',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: { xs: '100%', sm: 'auto' },
                        '&:hover': {
                            opacity: 0.8,
                            color: 'white',
                        },
                    }}
                >
                    Acesso Administrativo
                </Button>
            </Stack>
            <Divider component="div" role="presentation" fullWidth sx={{
                backgroundColor: '#ff5019',
                height: '5px',
                width: '35.2vw',
                marginTop: '-2%'
            }} />

            {!(selectedGroup || selectedSubgroup) &&
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}>
                        <TextField
                            placeholder='Pesquisar dúvida'
                            onChange={(e) => setPesquisaText(e.target.value)}
                            fullWidth
                            variant="outlined"
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IoMdSearch />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Box>

                    <Grid2
                        container
                        spacing={1}
                        minHeight={'50vh'}
                    >
                        {groups.map(group => (
                            <Grid2

                                size={{ xs: 12, md: 6 }}
                                minHeight={'200px'}
                                key={group.ID_DM001}
                            >
                                <Card
                                    fullWidth
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        flexGrow: 1,
                                        p: 2,
                                        boxShadow: 3,
                                        height: '100%'
                                    }}
                                >
                                    <Box sx={{
                                        mb: 10
                                    }}>
                                        <Typography variant="h5" component="h5" gutterBottom>
                                            {group.NM_GRUPO}
                                        </Typography>

                                        {group.children && group.children.length > 0 && (
                                            <Box sx={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', gap: 2}}>
                                                {group.children.map(child => (
                                                    <Button
                                                        key={child.ID_DM001}
                                                        onClick={() => {
                                                            handleViewGroup(group.ID_DM001);
                                                            handleViewSubGroup(child.ID_DM001);
                                                        }}
                                                        variant="text"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            flexDirection: 'row',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textAlign: 'left',
                                                            justifyContent: 'flex-start', // Alinha o conteúdo à esquerda
                                                            width: '100%', // Garante que o botão ocupe 100% da largura
                                                            color: '#ff5019',
                                                        }}
                                                    >
                                                        <IoIosArrowForward />
                                                        {child.NM_GRUPO}
                                                    </Button>

                                                ))}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                                        <Button
                                            variant="text"
                                            onClick={() => handleViewGroup(group.ID_DM001)}
                                            sx={{ color: '#ff5019', fontWeight: 'bold' }}
                                        >
                                            Ver mais
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </>
            }
            {selectedSubgroup && (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: '1rem',
                                        marginBottom: '1rem',
                                        gap: '1rem',
                                    }}>
                    <Button
                        variant="outlined"
                        width="100px"
                        sx={{
                            fontWeight: 'bold',
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'left',
                            justifyContent: 'flex-start', // Alinha o conteúdo à esquerda
                            width: 'fit-content', // Garante que o botão ocupe 100% da largura
                            color: '#ff5019',
                            border: '1px solid #ff5019',
                        }}
                        onClick={() => {setSelectedGroup(null); setSelectedSubgroup(null)}}
                    >
                        <IoMdArrowBack />
                        Voltar
                    </Button>
                    <Grid2 container spacing={2}>

                        {
                            groups.find(group => group.ID_DM001 === selectedGroup).children
                            .filter(child => child.ID_DM001 === selectedSubgroup)
                            .map(group => (
                                <Grid2
                                    item
                                    size={{ xs: 12, md: 6 }}
                                    key={group.ID_DM001}
                                >
                                    <Card
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            flexGrow: 1,
                                            p: 2,
                                            boxShadow: 3,
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h5" component="h5" gutterBottom>
                                                {group.NM_GRUPO}
                                            </Typography>

                                            {tutorials[group.ID_DM001] && tutorials[group.ID_DM001].map(tutorial => (
                                                <Typography
                                                    key={tutorial.ID_DM001}
                                                    variant="body1"
                                                    component="p"
                                                    flexWrap={'nowrap'}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <Button
                                                        variant="text"
                                                        component={Link}
                                                        to={`./Tutorial/${tutorial.ID_DM002}/${tutorial.DS_TITULO_TUTORIAL.replace('/', '')}`}
                                                        sx={{
                                                        color: '#ff5019',
                                                        fontWeight: 'bold', 
                                                        whiteSpace: 'nowrap',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                         }}
                                                    >
                                                        <IoIosArrowForward />
                                                        {tutorial.DS_TITULO_TUTORIAL}
                                                    </Button>
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Card>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </Box>
            )}

            {selectedGroup && (
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginTop: '1rem',
                                                        marginBottom: '1rem',
                                                        gap: '1rem',
                                                    }}>
                    {selectedSubgroup && (
                        <Typography marginTop={10} variant="h3" component="h1" gutterBottom>
                            Relacionados
                        </Typography>
                    )}
                    {!selectedSubgroup && (
                                            <Button
                                            variant="outlined"
                                            width="100px"
                                            sx={{
                                                fontWeight: 'bold',
                                                flexDirection: 'row',
                                                display: 'flex',
                                                alignItems: 'center',
                                                textAlign: 'left',
                                                justifyContent: 'flex-start', // Alinha o conteúdo à esquerda
                                                width: 'fit-content', // Garante que o botão ocupe 100% da largura
                                                color: '#ff5019',
                                                border: '1px solid #ff5019',
                                            }}
                                            onClick={() => {setSelectedGroup(null); setSelectedSubgroup(null)}}
                                        >
                                            <IoMdArrowBack />
                                            Voltar
                                        </Button>
                    )}
                    <Grid2 container spacing={2}>

                        {
                            groups.find(group => group.ID_DM001 === selectedGroup).children.map(group => (
                                <Grid2
                                    item
                                    size={{ xs: 12, md: 6 }}
                                    key={group.ID_DM001}
                                >
                                    <Card
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            flexGrow: 1,
                                            p: 2,
                                            boxShadow: 3,
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="h5" component="h5" gutterBottom>
                                                {group.NM_GRUPO}
                                            </Typography>

                                            {tutorials[group.ID_DM001] && tutorials[group.ID_DM001].map(tutorial => (
                                                <Typography
                                                    key={tutorial.ID_DM001}
                                                    variant="body1"
                                                    component="p"
                                                    flexWrap={'nowrap'}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <Button
                                                        variant="text"
                                                        component={Link}
                                                        to={`./Tutorial/${tutorial.ID_DM002}/${tutorial.DS_TITULO_TUTORIAL.replace('/', '')}`}
                                                        sx={{ color: '#ff5019', fontWeight: 'bold' }}
                                                    >
                                                        <IoIosArrowForward />
                                                        {tutorial.DS_TITULO_TUTORIAL}
                                                    </Button>
                                                </Typography>
                                            ))}
                                        </Box>

                                        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                                            <Button
                                                variant="text"
                                                component={Link}
                                                to={`/tutorial/${group.ID_DM001}`}
                                                sx={{ color: '#ff5019', fontWeight: 'bold' }}
                                            >
                                                Ver mais
                                            </Button>
                                        </Box> */}
                                    </Card>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </Box>
            )}
        </Container>
    );
}

export default TutorialList;
