import React, { useState, useEffect } from "react";
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../images/logo_escrita.png';
import styled from 'styled-components';
import './style.js';
import bread from '../../images/segments/bread.png';
import cheers from '../../images/segments/cheers.png';
import coffe from '../../images/segments/coffee.png';
import fast_food from '../../images/segments/fast-food.png';
import food_truck from '../../images/segments/food-truck.png';
import hamburger from '../../images/segments/hamburger.png';
import hot_dog from '../../images/segments/hot-dog.png';
import pizza from '../../images/segments/pizza.png';
import restaurant from '../../images/segments/restaurant.png';
import jimi from '../../images/segments/jimi.png';
import steak from '../../images/segments/steak.png';
import ContactModal from '../../components/ContactModal';
import banner_tarja from '../../images/banner_tarja.jpg';

import { StyledBody, StyledBurguer, StyledMenuSegmentos, StyledMenu } from './style';

const Header = ({ theme, tarja }) => {
  const [open, setOpen] = useState(false);
  const [openSegmentos, setOpenSegmentos] = useState(false);
  const [openBurguer, setOpenBurguer] = useState(false);
  const [openBurguerTwo, setOpenBurguerTwo] = useState(false);

  const [title, setTitle] = useState('Datamais');

  useEffect(() => {
    const pageNameRaw = window.location.pathname.split('/').pop();

    // Função para formatar o nome da página
    const formatPageName = (name) => {
      // Se a string possui hífens
      if (name.includes('-')) {
        return name.split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
      }

      // Se a string é camelCase ou PascalCase
      return name
        .replace(/([A-Z])/g, ' $1')  // insere um espaço antes de cada letra maiúscula
        .trim() // remove espaços no início e fim
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // transforma a primeira letra de cada palavra em maiúscula
        .join(' ');
    };

    const formattedPageName = formatPageName(pageNameRaw);

    if (title.includes('-')) {
      const parts = title.split(' - ');
      const baseTitle = parts[0];
      document.title = `${baseTitle} - ${formattedPageName}`;
    } else {
      if (formattedPageName) {
        document.title = `${title} - ${formattedPageName}`;
      } else {
        document.title = `${title}`;
      }
    }
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e) => {
    setModalIsOpen(true);
    e.preventDefault();
  };



  return (
    <>
      <StyledBody open={open} openBurguerTwo={openBurguerTwo} theme={theme}>
        <span id='topo'></span>
        <div className="header_container">
          <header className="containerHome">
            <StyledBurguer theme={theme} open={open} openSegmentos={openSegmentos} openBurguer={openBurguer} openBurguerTwo={openBurguerTwo} onClick={() => {
              setOpenBurguer(!openBurguer);
              setOpenBurguerTwo(!openBurguerTwo);
              setOpen(!open);
              if (openBurguer) {
                setOpenBurguer(false);
                setOpen(false);
                setOpenSegmentos(false);
              }
            }}>
              <div className="burguer " />
              <div className="burguer " />
              <div className="burguer " />
            </StyledBurguer>
            <StyledMenuSegmentos openSegmentos={openSegmentos} theme={theme}>
              <nav className="header-segmentos">
                <div className="itens_menu">
                  <img src={pizza}
                    alt="pizza"
                    className="iconMenu"
                  />
                  <Link to="/Pizzaria">Pizzaria</Link>
                </div>
                <div className="itens_menu">
                  <img src={hamburger}
                    alt="hamburguer"
                    className="iconMenu"
                  />
                  <Link to="/Hamburgueria">Hamburgueria</Link>
                </div>
                <div className="itens_menu">
                  <img src={hot_dog}
                    alt="hot_dog"
                    className="iconMenu"
                  />
                  <Link to="/Lanchonete">Lanchonete</Link>
                </div>
                <div className="itens_menu">
                  <img src={restaurant}
                    alt="restaurant"
                    className="iconMenu"
                  />
                  <Link to="/Restaurante">Restaurantes</Link>
                </div>
                <div className="itens_menu">
                  <img src={jimi}
                    alt="JimiRobo"
                    className="iconMenu"
                  />
                  <Link to="/JimiRobo">Jimi Robô</Link>
                </div>
                <div className="itens_menu">
                  <img src={bread}
                    alt="bread"
                    className="iconMenu"
                  />
                  <Link to="/Padaria">Padarias e Confeitarias</Link>
                </div>
                <div className="itens_menu">
                  <img src={coffe}
                    alt="coffe"
                    className="iconMenu"
                  />
                  <Link to="/Cafeteria">Cafeteria</Link>
                </div>
                <div className="itens_menu">
                  <img src={cheers}
                    alt="cheers"
                    className="iconMenu"
                  />
                  <Link to="/Bar">Bares e Pubs</Link>
                </div>
                <div className="itens_menu">
                  <img src={steak}
                    alt="steak"
                    className="iconMenu"
                  />
                  <Link to="/Churrascaria">Churrascarias</Link>
                </div>
                <div className="itens_menu">
                  <img src={fast_food}
                    alt="fast_food"
                    className="iconMenu"
                  />
                  <Link to="/PracaAlimentacao">Praças de Alimentação</Link>
                </div>
                <div className="itens_menu">
                  <img src={food_truck}
                    alt="food_truck"
                    className="iconMenu"
                  />
                  <Link to="/FoodTruck">Food Truck</Link>
                </div>
              </nav>
            </StyledMenuSegmentos>
            <StyledMenu open={open}>
              <div className="row header-logo-row">
                <div className="col-sm-4 header-logo">
                  <Link to="/">
                    <img src={logo} alt="Logo Datamais" className="logoHeader" title="Datamais Sistemas" />
                  </Link>
                </div>
                <nav className="col-sm-8 header-links">
                  <Link to="/">Início</Link>
                  <Link to="/QuemSomos">Quem Somos</Link>
                  <Link
                    className="buttonSegmentos"
                    onClick={() => {
                      setOpenSegmentos(!openSegmentos);
                      setOpen(!open);
                    }}>
                    Segmentos
                  </Link>
                  <Link className="only_desktop" to="/Segmentos">Segmentos</Link>
                  <Link to="/JimiRobo">Jimi Robô</Link>
                  <Link to="/Suporte">Suporte</Link>
                  <a href="http://sistema.datamais.com.br/blog/">Blog</a>
                  <Link to="/seja-um-parceiro">Seja um Parceiro</Link>
                  <Link to="/TrabalheConosco">Trabalhe Conosco</Link>
                  <a href="https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0" className="whats_header" onClick={e => openModal(e)}><FaWhatsapp />&nbsp;Fale com nossa equipe</a>
                </nav>
              </div>
            </StyledMenu>
          </header>
        </div>
        <ContactModal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} />
      </StyledBody>
      {tarja &&
        <img src={banner_tarja} style={{'width': "100%"}}/>
      }
    </>
  );
}

export default Header;
