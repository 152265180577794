import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './style.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { IoIosCloseCircleOutline } from "react-icons/io";
import apiFormClient from '../../services/apiForm';

Modal.setAppElement('#root');

const ContactModal = ({ isOpen, onRequestClose, isExitIntent }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [fone, setFone] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [formaContato, setFormaContato] = useState('');

  useEffect(() => {
    if (isOpen) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Le-ZhspAAAAAOutYThci1AaXHaAlrv_DfgLe6Qq', { action: 'submit' }).then(token => {
          setRecaptchaToken(token);
        });
      });
    } else {
      setFormaContato('');
    }
  }, [isOpen]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(email !== "" && name !== "name"){
      const toastId = toast.loading("Aguarde...");
      apiFormClient.post('create_mail_client?company=datamais', {
        DS_EMAIL_CLIENTE: email,
        NM_CLIENTE: name,
        NR_FONE_CONTATO: unmaskPhoneNumber(fone),
        IN_FORMA_CONTATO: isExitIntent,
        recaptcha: recaptchaToken
      })
        .then(response => {
          if (response.data.success) {
            setName('');
            setEmail('');
            setFone('');
            onRequestClose();
            toast.dismiss(toastId);
            window.open(`https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0`, '_blank');
          }
        })
        .catch(error => {
          console.log(error);
          toast.dismiss(toastId);
        });
    }
  };

  const handlePhoneInput = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      value = value.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else {
      value = value.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
    }
    setFone(value);
  };

  const unmaskPhoneNumber = (number) => {
    return number.replace(/\D/g, "");
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="contact-modal">
      <IoIosCloseCircleOutline style={{fontSize: '30px', float: 'right', cursor: 'pointer'}} onClick={onRequestClose}/>
      {formaContato == 'S' ? (
          <p>Parece que você está saindo. Gostaria de falar com nossa equipe antes de ir?</p>
      ) : (
        <>
          <p><strong>Canal de atendimento comercial</strong></p>
          <p>Para iniciar o atendimento no WhatsApp preencha os campos abaixo:</p>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <label>
          Nome:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Digite seu melhor email' />
        </label>
        {
          isExitIntent && (
            <label>
              Telefone:
              <input type="tel" value={fone} onChange={handlePhoneInput} required placeholder='Digite seu telefone de contato' maxLength="15" />
            </label>
          )
        }
        {/* <p>{isExitIntent == 'W' ? 'Whats' : isExitIntent == 'E' ? 'Equipe' : isExitIntent == 'S' ? 'Saida' : isExitIntent}</p> */}
        <button type="submit">{isExitIntent ? 'Enviar' : 'Conversar no Whatsapp'}</button>
      </form>
      {/* <ToastContainer /> */}
    </Modal>
  );
};

export default ContactModal;