import styled from 'styled-components';

const StyledCardInfo = styled.div`
  .cardLP-Mobile {
    display: none;
  }

  .mockupMobile {
    display: none;
  }

  .wavebot {
    display: none;
  }

  .logoDatamais {
    margin-left: 42%;
    margin-bottom: 2%;
  }

  h1 {
    margin-top: 2%;
    text-align: center;
    color: #fe5b13;
    font-weight: 800;
    font-size: 55px;
  }

  .divh2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divh2 h2 {
    color: #fe5b13;
    font-weight: 800;
  }

  .cardLP {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4% 4% 0% 4%;
  }

  .card {
    height: 560px;
    width: 350px;
    box-shadow: 0 0 1em #ccc;
    margin: 2% 1%;
    border-radius: 18px !important;
  }

  .cardP {
    margin: auto auto 0px auto;
    color: #000;
    font-size: 25px;
    text-align: left;
    font-weight: 600;
    height: 75%;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    white-space: pre-wrap;
  }

  .div-btn-contact-bodyLPOne {
    width: 35%;
    margin: auto;
  }

  .btn-contact-bodyLPOne {
    position: sticky;
    width: 100%;
    margin: auto;
    height: 8vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#fefefe, #eeeeee, #d0d0d0, #767474);
    border-radius: 50px;
    box-shadow: inset 0px -15px 1em #ccc;
    white-space: nowrap;
    z-index: 15 !important;
  }

  .btn-contact-bodyLPOne a {
    align-content: center;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #fe5b13;
    font-weight: bold;
    font-size: 2.8vw;
  }

  .btn-contact-bodyLPOne a:hover {
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
  }

  h3 {
    text-align: center;
    color: #fe5b13;
    font-weight: 600;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 8%;
    margin-bottom: 0px;
    height: 10px;
  }

  @media only screen and (max-width: 800px) {
    .div-btn-contact-bodyLPOne {
      width: 100%;
      height: 8vh;
      margin-bottom: 40px;
    }

    .btn-contact-bodyLPOne {
      height: 20vw !important;
      width: 80%;
    }

    .btn-contact-bodyLPOne a {
      font-size: 5.5vw;
    }

    .divh2 {
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
    }

    .divh2 h2 {
      color: #fe5b13;
      font-weight: 800;
    }

    .mainImgLPLogo {
      margin: 25% auto 10% 5%;
      width: 90%;
    }

    h1 {
      margin-top: 10%;
      text-align: center;
      color: #fe5b13;
      font-weight: 800;
      font-size: 40px;
    }

    .cardLP {
      display: none;
    }

    .cardLP-Mobile {
      display: block !important;
      margin-top: 0px;
      padding-left: 5%;
      width: 95%;
    }

    ul li {
      color: #fe5b13;
      font-size: 28px;
      margin: 5% 0px 5% 0px;
    }

    ul li span {
      color: #404041;
      font-weight: 600;
    }

    h3 {
      text-align: center;
      color: #fe5b13;
      font-weight: 600;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 10%;
    }

    .mockupMobile {
      width: 100%;
      margin-bottom: -20%;
      display: inline;
      z-index: 2 !important;
    }

    .wavebot {
      transform: scale(-1);
      margin-bottom: -40%;
      z-index: 2 !important;
      position: sticky;
      display: inline;
    }
  }
`;

export default StyledCardInfo;