import { createGlobalStyle, keyframes } from 'styled-components';

// Animação
const shakeAnimation = keyframes`
  0%, 28.5%, 100% { transform: rotate(0deg); }
  2.5%, 7.5%, 12.5%, 17.5%, 22.5% { transform: rotate(-1.5deg); }
  5%, 10%, 15%, 20%, 25% { transform: rotate(1.5deg); }
`;

// Função para gerar um número aleatório
const getRandomDelay = () => Math.random() * 7;

// GlobalStyle com atraso aleatório
const GlobalStyle = createGlobalStyle`
  .button-animation {
    animation: ${shakeAnimation} 7s linear infinite;
    animation-delay: ${getRandomDelay()}s; /* Atraso aleatório */
  }
`;

export default GlobalStyle;
