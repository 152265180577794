import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import apiFormClient from '../../../services/apiForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { initPixelTrabalheConosco } from '../../../services/facebookPixel';
import { logPixelPageView } from '../../../services/facebookPixel';
import { initPixelConvTrkr } from '../../../services/facebookPixel';
import { initGA } from '../../../services/googleAnalytics';

const StyledBodyOneTC = styled.div`

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  
  [type="file"] + label {
      width:20%;
    background: #f15d22;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    padding: 2%;
    position: relative;
    transition: all 0.3s;
    
    &:hover {
      background-color:(#f15d22, 10%);
    }
    
    &.btn-1 {
      background-color: #ff6e0c;
      box-shadow: 0 6px #ff5415;

  
      &:hover {
        box-shadow: 0 4px darken(#f79159, 10%);
        top: 3px;
      }
    }
  }
.tituloTutorial{
    padding-top:1%;
    margin:3% 10% 0px 10%;
    display:flex;
    flex-direction: column;
    position:sticky;
}
.tituloTutorial h1{
    margin-bottom:2%;
    font-size:2.5vw;
    margin-right: auto;
}
.h1Subli{
    background-color:#ff5019;
    width:35.2vw;
    height:5px;
    margin-top:-2%;
    margin-bottom:2%;
}
.divForm {
   
}
.divForm form{
    display: flex;
    align-items: left;
    flex-direction: row;
    margin: 0px 0px 0px 0px;
    height: auto;
    width: 100%;
    font-size: 1.4vw;
}
.divForm input{
    width:80%;
    background-color:#f1f1f1;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    
}
.formTwo input:nth-child(8){
    width:100%;
}
.divForm label{
  margin-top:1%;
}
.btn-enviarTC{
    font-size:1.5vw;
    margin:4% auto 5% auto;
    color: #f05123;
    font-weight: 600;
    border-radius: 20px ;
    width: 15vw;
    height: 7vh;
    cursor:pointer;
}
.formOne{
  display: flex;
  flex-direction:column;
  width:56%;
}
.formTwo{
    display: flex;
    width:44%;
    height:auto;
    flex-direction:column;
}
.formTwo h4{
    font-size:1.5vw;
    left:52.5vw;
    top:13vw;
    position:absolute
}
.anexar{
    display: ${({ resumeConfirm }) => resumeConfirm ? 'none' : ''} !important;
}
.anexado{
    display: ${({ resumeConfirm }) => resumeConfirm ? '' : 'none'} !important;
}
.tamTextArea{
    height:40%;
    width:100%;
    background-color:#f1f1f1;
    border:none;
}
.tituloTutorial p{
    font-size:1vw;
    margin-top:1%;
}

img {
    max-width: 100%;
}

iframe {
    min-width: 50%;
    height: 40vh;
}

@media only screen and (max-width: 800px){
    [type="file"] + label {
     width:25vw;
     
    }

    .divForm input {
      width: 100%;
  }

    .tituloTutorial h1{
        margin-bottom:2%;
        font-size:7vw;
        margin-right: auto;
        text-align:center;
    }
    .h1Subli{
        background-color:#ff5019;
        width:35.2vw;
        height:5px;
        margin:-2% auto auto auto;
    }
    .divForm form{
        display: flex;
        align-items: left;
        flex-direction: column;
        margin: 2% 0px 0px 0px;
        height: auto;
        width: 100%;
        font-size: 5vw;
    }
    .formOne{
        display: flex;
        flex-direction:column;
        width:100%;
      }
      .formTwo{
          display: flex;
          width:100%;
          height:auto;
          flex-direction:column;
      }
      .btn-enviarTC{
        font-size:4vw;
        margin:8% auto 5% auto;
        border-radius: 20px;
        width: 30vw;
        height: 6vh;
    }
    .formTwo h4{
        font-size:4vw;
        left:27vw;
        top:119vw;
        position:absolute;
    }
    .tituloTutorial p{
        font-size:3vw;
        margin-top:5%;
    }
    .tamTextArea{
        height:40%;
        width:100%;
        background-color:#f1f1f1;
        border:none;
    }
    .formTwo input:nth-child(8){
        width:80%;
    }
}
`;

const TutorialDetails = ({ Id, ...rest }) => {
    const [title, setTitle] = useState('');
    const [autor, setAutor] = useState('');
    const [data, setData] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        const toastId = toast.loading("Carregando tutorial...");
        apiFormClient.post('filter_tutorials?company=datamais', {
            ID_DM002: Id,
            isContent: true
        })
        .then(response => {   
            setContent(response.data[0].DS_TEXTO_TUTORIAL);      
            setAutor(response.data[0].NM_USUARIO);
            setData(formatDate(response.data[0].DT_ALTERACAO));
            setTitle(response.data[0].DS_TITULO_TUTORIAL);  
            toast.dismiss(toastId);    
        })
        .catch(error => {
            console.log(error);
            toast.dismiss(toastId);
        });
    }, []);  
    
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    

    return (
        <StyledBodyOneTC >
            <div className='tituloTutorial'>
                <h1>{title}</h1>
                <></>
                <div className='h1Subli' />
                <h5>{autor} | {data}</h5>
            
                <div dangerouslySetInnerHTML={{ __html: content }} />

                <ToastContainer />
            </div>
        </StyledBodyOneTC>
    )
}

export default TutorialDetails;
