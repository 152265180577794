
import styled from'styled-components';

export const StyledBodyOne = styled.div`
.col-sm-12-Mobile{
    display:none;
}
.wavebotMobile{
    display:none;
}
.wavetopMobile{
    display:none;
}
.info-first-img{
    position: absolute;
    top: 28vw;
    width: 35%;
    height: 44%;
    left: 11%;
    align-items: center;
}

.info-first-img p:nth-child(1){
    font-size: 3vw;
    white-space: nowrap;
    font-weight: 600;
}

.info-first-img p:nth-child(2){
    font-size: 2vw;
}


.info-first-img p{
    color: #fff;
    font-size: 38px;
    font-weight: 500;

}

.video-first{
    position: absolute;
    top: 28vw;
    width: 35%;
    height: 44%;
    right: 11%;
    align-items: center;

    iframe {
        max-width: 100%;
        height: 20vw;
    }
}

.mainImgLP{
    width: 100% !important;
    z-index: 1 !important;
}
.div-btn-contact-bodyLP{
    white-space: nowrap;
    width: 35%;
    left: 11%;
    position: absolute;
    top: 52vw;
    height: 10vh;
}

.btn-contact-bodyLP{
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 50px;
    box-shadow: inset 0px 2px 20px 0px #c3c3c3;
    margin-bottom: 5%;
    background: linear-gradient(#fefefe,#eeeeee,#d0d0d0,#767474);
}

.btn-contact-bodyLP a{
    align-content: center;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #fe5b13;
    font-size: 2vw;
    font-weight: bold;
}

.btn-contact-bodyLP a:hover{
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
}
.infoLP{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    justify-content: center;
    background-color: #ff5019;
    padding: 3% 0 3% 0; 
}
.infoLP p{
    align-items: center;
    font-weight: 700;
    text-align: center;
    color: #fff;
    font-size: 35px;
}
.divInfo{
    width: 70%;
    height: 40%;
}

    h1{
        margin-top: 2%;
        text-align: center;
        color: #fe5b13;
        font-weight: 800;
        font-size: 55px;
    }
   
    @media only screen and (max-width: 800px) {
        .col-sm-12-Mobile{
            display: inline !important;
        }
        .col-sm-12{
            display: none;
        }
        .info-first-img{
            position: absolute;
            top: 28%;
            left: 5%;
            width: 55%;
        }
        .info-first-img p{
            color: #fff;
            font-weight: 500;
            text-align: left;
        }
        .info-first-img p:nth-child(1){
            font-size: 5.6vw;
            white-space: nowrap;
            font-weight: 600;
        }
        
        .info-first-img p:nth-child(2){
            margin-top:-10%;
            font-size: 5.6vw;
            letter-spacing: 0.9vw;
            font-weight: 600;
            white-space: nowrap;
        }
        
        .info-first-img p:nth-child(3){
            font-size: 4.4vw;
            font-weight: 500;
        }
        .div-btn-contact-bodyLP{
            width: 80%;
            position: absolute;
            top: 110vw;
            height: 5vh;
        }
        .btn-contact-bodyLP{
            height: 17vw;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(#fefefe,#eeeeee,#d0d0d0,#767474);
            padding: 15px;
            border-radius: 60px;
            box-shadow: inset 0px 2px 20px 0px #c3c3c3;
            margin-bottom: 5%;
        }
        .btn-contact-bodyLP a{
            align-content: center;
            text-decoration: none;
            color: #fe5b13;
            font-size: 5vw;
            font-weight: bold;
        }
        .infoLP{
            position: sticky;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            width: 100%;
            justify-content: center;
            background-color: #f05123;
            padding: 0px;
            margin-top:-3.2vh;
        }
        .infoLP p{
            align-items: center;
            font-weight: 700;
            text-align: center;
            color: #fff;
            font-size: 25px;
        }
        .divInfo{
            width: 90%;
            height: 80%;
            margin-top:3%;
        }
        .wavebotMobile{
            display:inline;
            margin-top:-25%;
        }
        .wavetopMobile{
            display:inline;
            margin-top:-2vh;
        }
    }
    
    .wavebot{
        z-index: 11 !important;
        margin-top: -20%;
    }
    .wavetop{
        z-index: 0 !important;
        position: absolute;
        margin-top: -15%;
    }
    .mainImgLPLogo{
        width: 15%;
        margin-left: 15%;
    }

    .carroselJimi img{
        margin: 10px;
        max-width: 92%;
    
        .slick-slide:focus { outline: none; }
    }
    

    @media only screen and (max-width: 800px) {
        .col-sm-12-Mobile {

            
        .info-first-img{
            margin-top: -30px;
            width: 90%;
                p {
                    font-size: 20px !important;
                }
            }
        
            .mainImgLP {
                height: 680px;
                object-fit: cover;
            }
        }

        .div-btn-contact-bodyLP{
            top: 100vw;
        }

        .video-first {
            top: 105vw;
            width: 100%;
            display: flex;
            align-items: center;
            right: 0px;
            justify-content: center;
        
            iframe {
                height: 200px;
                width: 90%;
                margin-top: 50px;
            }
        }

        .col-sm-12-Mobile .info-first-img p {
            font-size: 20px !important;
            height: 50px;
        }

        .carroselJimi img {
            margin: 5px;
            max-width: 100%;
        }

        .slick-slide {
            padding: 10px;
        }

    }
    .wavebotMobile {
        display: none;
    }

    .carroselJimi {
        margin: 0 auto; /* Center the carousel container */
      }
    
      @media only screen and (max-width: 800px) {
        /* Adjust carousel container styles for smaller screens */
        .carroselJimi {
          max-width: 90%; /* Reduce the maximum width for small screens */
          padding: 0; /* Remove padding for small screens */
        }
      }

      .slick-prev, .slick-next{
        margin: 40px;
        z-index: 1000;
      }

      .slick-prev:before, .slick-next:before{
        font-size: 30px;
      }

`;
