import React, {useState} from 'react';
import styled from 'styled-components';
import logo from '../../../../images/logo_escrita.png';
import check from '../../../../images/jimi/check.png';
import error from '../../../../images/jimi/error.png';
import jimi from '../../../../images/jimi/jimi.png';
import mockup from '../../../../images/lp_datamais/mockupMobile.jpg';
import wavebot from '../../../../images/lp_datamais/wavebot.svg';
import StyledCardInfo from './style';
import { MdOutlineTouchApp } from 'react-icons/md';
import { BsFillVolumeUpFill } from 'react-icons/bs';
import { ImPowerCord } from 'react-icons/im';
import { AiOutlineUsergroupAdd, AiFillWechat } from 'react-icons/ai';
import { FaBalanceScale } from 'react-icons/fa';
import ContactModal from '../../../ContactModal';


const solutions = [
  {
    title: 'Automação na pesagem',
    description:
      'Conectado à sua balança\nrealiza a pesagem\ndo prato automático\ne exibe o valor\nna tela do Jimi',
  },
  {
    title: 'Deseja boas vindas',
    description:
      'De forma simpática,\ndeseja boas vindas\naos clientes\ncom diversas frases',
  },
  {
    title: 'Registra o consumo',
    description:
      'Registra o consumo\ndo cliente em uma\ncomanda de papel\nou\ncomanda eletrônica',
  }
];

const problems_list = [
  {
    "text": "Funcionário parado na balança"
  },
  {
    "text": "Comanda com anotações erradas"
  },
  {
    "text": "Filas para pesar o prato"
  },
  {
    "text": "Falta de integração com o sistema"
  },
  {
    "text": "Filas para pagamento no caixa"
  }
];

const solutions_list = [
  {
    "text": "Não precisa de funcionário na balança"
  },
  {
    "text": "Comandas registradas sem erros"
  },
  {
    "text": "Nunca falta no trabalho"
  },
  {
    "text": "Arranca sorrisos dos clientes"
  },
  {
    "text": "Agiliza a fila na balança, pesa rápido"
  },
  {
    "text": "Facilita a cobrança no caixa integrado"
  }
];

const currentYear = new Date().getFullYear();
const foundingYear = 1997;
const yearsOfExperience = currentYear - foundingYear;

const CardInfo = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e) => {
      setModalIsOpen(true);
      e.preventDefault();
  };

  return (
    <StyledCardInfo>
      <h1>Como funciona o atendimento com o Jimi?</h1>
      <div className='cardLP'>
        {solutions.map((solution, index) => (
          <div className='card' key={index}>
            <h3>{solution.title}</h3>
            <div className='cardP'>
              <p>{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='divh1'>
        <h1>Jimi Robô é o atendente ideal<br /> para o seu negócio!</h1>
      </div>
      <div className='text-info-jimi'>
        <p>
          <strong>Estamos há mais de {yearsOfExperience} anos auxiliando<br /> estabelecimentos com agilidade, economia<br /> e inovação no atendimento.</strong>
        </p>
      </div>
      <br />
      <br />
      <div className='div-btn-contact-bodyLP button-animation'>
        <div className="btn-contact-bodyLP button-animation" onClick={e=>openModal(e)}>
          <a href="https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0">Fale com nossa equipe!</a>
        </div>
      </div>
      <div className='divh1'>
        <br />
        <h1>Problemas no seu restaurante</h1>
      </div>
      <div className='jimi-list-attr'>
        {problems_list.map((item, index) => (
          <div className='row item' key={index}>
            <div className='col-md-1 col-2 img-list'>
              <img src={error} />
            </div>
            <div className='col-md-11 col-9 text-list'>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='divh1'>
        <br />
        <h1>Soluções com o Jimi Robô</h1>
      </div>
      <div className='jimi-list-attr'>
        {solutions_list.map((item, index) => (
          <div className='row item' key={index}>
            <div className='col-md-1 col-2 img-list'>
              <img src={check} />
            </div>
            <div className='col-md-11 col-9 text-list'>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />
      <div className='specs-jimi'>
        <h1>Simpático, rápido e compacto!</h1>

        <div className='row'>
          <div className='col-sm-6'>
            <img src={jimi} />
          </div>
          <div className='col-sm-6 attrs-jimi'>
            <div className='attr'>
              <MdOutlineTouchApp /><p> Tela touch screen</p>
            </div>
            <div className='attr'>
              <BsFillVolumeUpFill /><p> Controle de volume</p>
            </div>
            <div className='attr'>
              <ImPowerCord /><p>Bivolt<br />110v / 220v</p>
            </div>

          </div>
        </div>
      </div>
      <div className='depoiment'>
        <p>Veja como o Jimi tem mudado a realidade de diversos <br />estabelecimentos por todo Brasil</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/4iIgEMJAPsk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className='div-btn-contact-bodyLP button-animation'>
        <div className="btn-contact-bodyLP button-animation" onClick={e=>openModal(e)}>
          <a href="https://api.whatsapp.com/message/NOV4TGWO6YLYO1?autoload=1&app_absent=0">Fale com nossa equipe!</a>
        </div>
      </div>
      <div className='text-info-jimi' style={{ 'padding': '20px', 'marginTop': '60px' }}>
        <h1 style={{ 'color': '#fff', 'margin': '20px' }}>
          Vantagens que vão além da balança!
        </h1>
      </div>
      <div className='specs-jimi2'>
        <div className='attrs-jimi'>
          <div className='attr'>
            <AiOutlineUsergroupAdd /><p> Atrai novos clientes</p>
          </div>
          <div className='attr'>
            <ImPowerCord /><p> Integrado ao <br />sistema de gestão</p>
          </div>
          <div className='attr'>
            <FaBalanceScale /><p>2 balanças <br />simultâneas</p>
          </div>
          <div className='attr'>
            <AiFillWechat /><p>Suporte todos os dias <br />08:00 às 00:00 horas</p>
          </div>
        </div>
      </div>
      <img src={wavebot} className='wavebot' alt='wave' />
      <img src={mockup} className='mockupMobile' alt='First img' />
      <ContactModal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} isExitIntent={'E'}/>
    </StyledCardInfo>
  );
};

export default CardInfo;
