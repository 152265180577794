import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import BodyJimiRobo from "../../../components/Body/JimiRobo";

const JimiRobo = () => {
    return(
        <>
        <Header theme="white" tarja="true"/>
        <BodyJimiRobo />
        <Footer />
        </>
    )
  }
export default JimiRobo;
